/* Footer Styles */
.footer {
    margin-top: -10px;
    background-color: #d68400;
    color: #ffffff;
    text-align: center;
    padding: 1rem 2rem;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer .social-links {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 0;
  }
  
  .footer .social-links a {
    color: #1a1a1d; /* Accent color */
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .footer .social-links a:hover {
    color: #e09e2c;
  }
  
  .footer p {
    font-size: 0.875rem;
    margin-top: 1rem;
    color: #dddddd;
  }
  