.ai-project-page {
  padding: 20px;
  background-color: #1a1a1d;
  color: rgb(228, 227, 227);
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
}

.ai-project-header {
  text-align: center;
  margin-bottom: 30px;
}

.ai-project-header h1 {
  font-size: 2.5rem;
  color: #fca311;
}

.ai-project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between items */
}

.ai-project-image {
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Ensure horizontal centering */
  text-align: center;
}

.ai-project-image p {
  font-size: 0.9rem;
  margin-bottom: 10px;
  font-style: italic;
}

.ai-project-description {
  max-width: 600px;
  text-align: left; /* Align text to the left */
}

.ai-project-description p {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.ai-project-links {
  text-align: center; /* Center the buttons */
}

.ai-project-links a {
  display: inline-block;
  margin-right: 15px;
  padding: 10px 20px;
  background-color: #fca311;
  color: #1a1a1d;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.ai-project-links a:hover {
  background-color: #f0b208;
}