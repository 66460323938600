.chess-game-page {
  padding: 20px;
  background-color: #1a1a1d;
  color: rgb(228, 227, 227);
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
}

.chess-game-header {
  text-align: center;
  margin-bottom: 30px;
}

.chess-game-header h1 {
  font-size: 2.5rem;
  color: #fca311;
}

.chess-game-header h2 {
  font-size: 1.5rem;
  color: #fca311;
  text-align: left;
}

.chess-game-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between items */
}

.chess-game-image {
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Ensure horizontal centering */
  text-align: center;
}

.chess-game-image p {
  font-size: 0.9rem;
  margin-bottom: 10px;
  font-style: italic;
}

.chess-game-description {
  max-width: 600px;
  text-align: left; /* Center text for better alignment */
}

.chess-game-description p {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.chess-game-description ul {
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.chess-game-links {
  text-align: center; /* Center the buttons */
}

.chess-game-links a {
  display: inline-block;
  margin-right: 15px;
  padding: 10px 20px;
  background-color: #fca311;
  color:#1a1a1d;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.chess-game-links a:hover {
  background-color: #f0b208;
}

.code-block {
  margin-top: 20px;
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.table-container {
  margin: 20px auto;
  max-width: 800px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.summary-table th,
.summary-table td {
  border: 1px solid #7d7d7d;
  padding: 10px;
}

.summary-table th {
  background-color: #1a1a1d;
  color: #fca311;
  text-transform: uppercase;
}

.summary-table tr:nth-child(even) {
  background-color: #373737;
}

.summary-table tr:nth-child(odd) {
  background-color: #4c4c4c;
}

.summary-table tr:hover {
  background-color: #fca311;
  color: rgb(0, 0, 0);
}

.summary-table td {
  text-align: left;
}

.word-highlight {
  color: #fca311; 
  font-weight: bold;
}

.scroll-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #fca311;
  color:#1a1a1d;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.scroll-button:hover {
  background-color: #ffbe0b;
  transform: scale(1.1); /* Slight zoom effect on hover */
}


