.contact-page {
    padding: 20px;
    background-color: #1a1a1d;
    color: #e4e3e3;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    text-align: center;
  }
  
  .contact-container {
    max-width: 600px;
    margin: 0 auto;
    background-color: #2c2c34;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .contact-container h1 {
    font-size: 2.5rem;
    color: #fca311;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    text-align: left;
  }
  
  .form-group label {
    font-size: 1.1rem;
    margin-bottom: 5px;
    display: block;
    color: #fca311;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #4e4e50;
    color: #e4e3e3;
    font-size: 1rem;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #fca311;
  }
  
  .submit-button {
    background-color: #fca311;
    color: #1a1a1d;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .submit-button:hover {
    background-color: #ffbe0b;
    transform: scale(1.05);
  }
  
  .success-message {
    font-size: 1.2rem;
    color: #4caf50;
    margin-top: 20px;
  }
  
  .error-message {
    font-size: 1.2rem;
    color: #f44336;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .contact-container {
      padding: 15px;
    }
  
    .contact-container h1 {
      font-size: 2rem;
    }
  
    .form-group input,
    .form-group textarea {
      font-size: 0.9rem;
    }
  
    .submit-button {
      font-size: 1rem;
    }
  }
  